import { API } from "../config/AppConfig";
import { AUDITOR_LOGIN, FORGOT_PASSWORD, LOGOUT, USER_LOOKUP } from "../constants/Endpoints";
import { ACCESS_TOKEN } from "../constants/PreferenceKeys";

class AuthRepository {

  async login(payload) {
    localStorage.removeItem(ACCESS_TOKEN)

    const response = await API.post(AUDITOR_LOGIN, {
      body: payload
    });
    if (response.access_token) {
      localStorage.setItem(ACCESS_TOKEN, `Bearer ${response.access_token}`)
    }
    return response;
  }

  async userLookup() {
    const response = await API.get(USER_LOOKUP);
    return response;
  }
  async forgotPassword(payload) {
    const response = await API.post(FORGOT_PASSWORD, {
      body: payload
    });
    return response;
  }

  async resetPassword(payload) {
    const response = await API.put(FORGOT_PASSWORD, {
      body: payload
    });
    return response;
  }

  async logout() {
    const response = await API.post(LOGOUT);
    return response;
  }
}

const authRepository = new AuthRepository();
export default authRepository;