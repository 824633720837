import { useEffect, useState } from 'react';
// isTablet, isWeb, isMobile;
const useDeviceScreen = () => {
  const [isWeb, setIsWeb] = useState(
    window.matchMedia(`(min-width: 1600px)`).matches
  );
  const [isTablet, setIsTablet] = useState(
    window.matchMedia(`(min-width: 1200px)`).matches
  );
  const [isMobile, setIsMobile] = useState(
    window.matchMedia(`(min-width: 800px)`).matches
  );
  useEffect(() => {
    window
      .matchMedia(`(min-width: 1600px)`)
      .addEventListener('change', e => setIsWeb(e.matches));
    window
      .matchMedia(`(min-width: 1200px)`)
      .addEventListener('change', e => setIsTablet(e.matches));
    window
      .matchMedia(`(min-width: 800px)`)
      .addEventListener('change', e => setIsMobile(e.matches));
  }, []);

  return {
    isWeb,
    isTablet,
    isMobile,
  };
};

export default useDeviceScreen;
