/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import Handsontable from 'handsontable';
import { HotTable } from '@handsontable/react';
import 'handsontable/dist/handsontable.min.css';
import {
  AUDIT_COLUMN_HEADER,
  NUMERIC_KEYS,
  RENDERING_AS_COL,
  RESPONSE_AS_COL,
  dynamicColumnKeywords,
} from '../constants';
import { registerAllModules } from 'handsontable/registry';
import '../styles.css';
import { useBoolean } from '@chakra-ui/react';
import { useContext } from 'react';
import { AuditSheetContext } from '../../../providers/AuditSheetProvider';
import { useInterval } from '../../../hooks';
import { useToastr } from '@laxmimanogna/code-quick-components';
import { getRowColArray, getRowColIndex } from '../getRowColumn';
import AppColors from '../../../constants/AppColors';
import { registerCustomCells } from './hands_on_table_renderers/CustomRenderers';
import { format } from 'date-fns';
import { toTitleCase } from '../../../utils/common.utils';
import {
  creatColumnHeader,
  createColumnDataName,
  emptyDataPrefilledObject,
  findAgreeDisAgreeColumn,
  findDollorColumn,
  findHighlightedColumn,
  findNumericColumns,
  getAuditSheetKeys,
  isColumnMatched,
  isEmptySheet,
  prepareAuditSheetColumn,
  prepareAuditSheetHeader,
  validateUnits,
  handleAgreeDisagree,
  updateRVUand$Value,
  getBaseKeyword,
  validateCPT,
  cptSanitize,
  validateColumnValue,
} from '../../../utils/auditsheet.utils';

registerCustomCells({});

function getScaledRowHeight(scale) {
  return `${24 * scale}px`;
}

function getScaledHeaderFontSize(scale) {
  return 13 * scale + 'px';
}

function getScaledRowFontSize(scale) {
  return 14 * scale + 'px';
}

const AuditSheet = ({
  scale = 1,
  sheet,
  uploadId,
  view,
  updateAuditSheet,
  updateSheet,
  onCommentClick,
  closeComment,
  height,
  removeRows,
}) => {
  // props

  /**
   *  { updateSheet } update the data in setState(setSheet);
   *  { updateAuditSheet } create audit sheet(POST) and getSheets and update sheet;
   *  { sheet } gives a current sheet not sheets
   */

  // context props
  const auditSheetContext = useContext(AuditSheetContext);
  const {
    industryCodes,
    getComments,
    commentFlags,
    providerOptions,
    currentUpload,
  } = auditSheetContext;

  const {
    setAuditSheetColumnsKeys,
    setHilightedColumn,
    setDollorColumn,
    setAgreeDisAgreeCol,
  } = auditSheetContext;

  const {
    highlightedColumn,
    dollarColumn,
    auditSheetColumnsKeys,
    agreeDisAgreeCol,
  } = auditSheetContext;

  // booleans
  const [isProviderDollarDisabled, ipddState] = useBoolean(false);
  const [isAuditedDollarDisabled, iaddState] = useBoolean(false);

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  //states
  const [sheetData, setSheetData] = useState([]);
  const [windowSize, setWindowSize] = useState(getWindowSize().innerWidth);
  const [numericColumns, setNumericColumns] = useState([]);

  const [columnData, setColumnData] = useState([]);
  const [auditSheetHeaders, setAuditSheetHeaders] = useState([]);

  const [localHeader, setLocalHeader] = useState(auditSheetHeaders);
  const [localColumnData, setLocalColumnData] = useState(columnData);

  // other hooks
  const toast = useToastr();

  registerAllModules(); // importing all modules in compoenent level

  // life cycle hooks
  useEffect(() => {
    registerCustomCells({ scale });
  }, [scale]);

  useEffect(() => {
    if (sheet?.data) {
      setLocalSheetData(sheet?.data);
    }
    // sheet is empty , remove additional columns
    const isNewSheet = isEmptySheet(sheet.data);
    const preFilledObject = emptyDataPrefilledObject(currentUpload);
    if (isNewSheet && Object.keys(currentUpload).length) {
      const preFilledEmptyObject = sheet.data.map((s, i) => {
        if (i === 0) {
          return preFilledObject;
        } else {
          return s;
        }
      });
      updateSheet(preFilledEmptyObject);
      const auditSheetColumnsheaders =
        prepareAuditSheetHeader(preFilledEmptyObject);
      setAuditSheetHeaders(auditSheetColumnsheaders);
      //  -- preparing dynamic auditsheetcolummn --
      const dynamicColumnData = prepareAuditSheetColumn(
        preFilledEmptyObject,
        windowSize,
        view,
        providerOptions,
        isAuditedDollarDisabled,
        isProviderDollarDisabled
      );
      if (!!dynamicColumnData.length) {
        setColumnData(dynamicColumnData);
      }
    }
  }, [sheet?.data, currentUpload]);

  useEffect(() => {
    function handleWindowResize() {
      const size = getWindowSize();
      setWindowSize(size.innerWidth);
    }
    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  React.useEffect(() => {
    setColumnData(localColumnData);
    setAuditSheetHeaders(localHeader);
  }, [localHeader, localColumnData]);

  React.useEffect(() => {
    const reDestructuredData = sheet?.data;

    // spreading in object
    const _reDestructuredData = reDestructuredData.map(sd => {
      const tempObj = { ...sd, ...sd?.additional_attributes };

      delete tempObj.additional_attributes;

      return tempObj;
    });

    // -- preparing dynamic column headers --
    const auditSheetColumnsheaders =
      prepareAuditSheetHeader(_reDestructuredData);
    setAuditSheetHeaders(auditSheetColumnsheaders);

    // -- preparing dynamic auditsheetcolummn --
    const dynamicColumnData = prepareAuditSheetColumn(
      _reDestructuredData,
      windowSize,
      view,
      providerOptions,
      isAuditedDollarDisabled,
      isProviderDollarDisabled
    );

    if (!!dynamicColumnData.length) {
      setColumnData(dynamicColumnData);
    }
  }, [sheet]);

  useEffect(() => {
    const hot = hotRef.current.hotInstance;

    if (
      Object.keys(auditSheetContext.selectedCell).length &&
      sheetData.length
    ) {
      const { row, col } = auditSheetContext.selectedCell;
      const index = sheetData.findIndex(d => d.id === row);
      if (index !== -1) {
        hot.selectCell(index, col);
      }
    } else {
      hot.deselectCell();
    }
  }, [auditSheetContext.selectedCell]);

  React.useEffect(() => {
    const _hilghtedColumn = findHighlightedColumn(columnData);
    const _dollorColumn = findDollorColumn(columnData);
    const _auditSheetkey = getAuditSheetKeys(columnData);
    const _agreeDisagree = findAgreeDisAgreeColumn(columnData);
    const _numericColumn = findNumericColumns(columnData);

    setAuditSheetColumnsKeys([..._auditSheetkey]);
    setHilightedColumn([..._hilghtedColumn]);
    setDollorColumn([..._dollorColumn]);
    setAgreeDisAgreeCol([..._agreeDisagree]);
    setNumericColumns(_numericColumn);
  }, [columnData.length]);

  // other hooks
  useInterval(() => {
    //call update audit sheet
    if (!view) {
      updateAuditSheet();
    }
  }, 50000);

  const hotRef = useRef(null);

  function setLocalSheetData(data) {
    if (!data) {
      setSheetData([]);
    }
    const nextSheetData = [];
    for (let i = 0; i < data.length; i++) {
      const datum = data[i];

      const renderingValue = datum.rendering;
      if (renderingValue) {
        if (
          typeof renderingValue === 'object' &&
          Object.keys(renderingValue).length
        ) {
          datum.rendering = toTitleCase(
            `${renderingValue.first_name} ${renderingValue.last_name}`
          );
          datum.old_rendering = renderingValue;
        } else {
          const providerOption = providerOptions.find(
            p => p.id === renderingValue
          );
          if (providerOption) {
            datum.rendering = providerOption.value;
          }
        }
      }
      if (datum.enc_dt) {
        const d = Date.parse(datum.enc_dt);
        if (d) {
          datum.enc_dt = format(new Date(d), 'MM-dd-yyyy');
        }
      }
      nextSheetData.push(datum);
    }
    //  des-object and set to setSheetData(local)
    setSheetData(nextSheetData);
  }

  function columnHighlight(
    instance,
    td,
    row,
    col,
    prop,
    value,
    cellProperties
  ) {
    Handsontable.renderers.TextRenderer.apply(this, arguments);
    td.style.background = AppColors.lightPink;
    td.style.fontSize = getScaledRowFontSize(scale);
    td.style.textAlign = 'center';
  }

  function commentCell(instance, td, row, col, prop, value, cellProperties) {
    Handsontable.renderers.TextRenderer.apply(this, arguments);
    td.className = `htCommentCell`;
    td.style.fontSize = getScaledRowFontSize(scale);
    td.style.textAlign = col !== RENDERING_AS_COL && 'center';
    const COL = highlightedColumn; // [4, 5, 10, 11];
    if (COL.includes(col)) {
      td.style.background = AppColors.lightPink;
    }
    if (agreeDisAgreeCol.includes(col)) {
      td.innerHTML = value === true ? 'Yes' : '-';
    }
    if (dollarColumn.includes(col)) {
      td.innerHTML = value ? `${value}` : null;
    }
  }

  const updateSheetData = (nextSheetData, row, rowIndex) => {
    if (rowIndex != null) {
      nextSheetData[rowIndex] = {
        ...nextSheetData[rowIndex],
        ...row,
        row_id: null,
        file_name: currentUpload.upload_id,
        chart_id: currentUpload.id,
      };
    } else {
      nextSheetData.push({ ...row, id: null });
    }

    return nextSheetData;
  };

  const onBeforeHotChange = change => {
    let nextSheetData = [...sheetData];
    change.map(changes => {
      const rowIndex = changes[0];
      const columnName = changes[1];
      const oldValue = changes[2];
      const newValue = changes[3];
      const baseColumnName = getBaseKeyword(columnName, dynamicColumnKeywords);

      if (newValue !== "") {
        const validationResult = validateColumnValue(columnName, newValue);
        if (!validationResult.isValid) {
          toast.showWarning({ description: validationResult.message });
          return;
        }
      }

      const isNewRow = rowIndex >= sheetData.length;
      switch (baseColumnName) {
        case AUDIT_COLUMN_HEADER.SRVCS_NO:
        case AUDIT_COLUMN_HEADER.AUDITED_CPT:
          //CPT Format:- CPT-Modifier-..*Units 
          if (!newValue || newValue === " ") {
            const row = {
              [columnName]: null,
            };
            nextSheetData = updateSheetData(
              nextSheetData,
              row,
              isNewRow ? null : rowIndex
            );
            nextSheetData = updateRVUand$Value(nextSheetData, rowIndex, industryCodes)
            iaddState.on();
          } else if (
            Object.keys(industryCodes).length &&
            validateCPT(newValue.split('*')[0], industryCodes) &&
            validateUnits(newValue)
          ) {
            const sanitizeCPT = cptSanitize(newValue);
            const row = {
              [columnName]: sanitizeCPT.cpt,
            };
            
            nextSheetData = updateSheetData(
              nextSheetData,
              row,
              isNewRow ? null : rowIndex
            );
            nextSheetData = updateRVUand$Value(nextSheetData, rowIndex, industryCodes)
            ipddState.on();
            
          } else {
            toast.showError({
              description: `CPT is not available or please check the format CPT*UNITS`,
            });
            const row = {
              [columnName]: null,
            };
            nextSheetData = updateSheetData(
              nextSheetData,
              row,
              isNewRow ? null : rowIndex
            );
            nextSheetData = updateRVUand$Value(nextSheetData, rowIndex, industryCodes)
          }

          break;
        case AUDIT_COLUMN_HEADER.BILLED_MODIFIER:
        case AUDIT_COLUMN_HEADER.AUDITED_MODIFIER:
          if(newValue){
            nextSheetData = updateSheetData(
              nextSheetData,
              {
                [columnName]: newValue === '' ? null : newValue.toUpperCase(),
              },
              isNewRow ? null : rowIndex
            );
            nextSheetData = updateRVUand$Value(nextSheetData, rowIndex, industryCodes)
          }else{
            nextSheetData = updateSheetData(
              nextSheetData,
              {
                [columnName]: null,
              },
              isNewRow ? null : rowIndex
            );
            nextSheetData = updateRVUand$Value(nextSheetData, rowIndex, industryCodes)
          }
          break;
        case AUDIT_COLUMN_HEADER.AGREE:
          const row = {
            ...nextSheetData[changes[0]],
            [changes[1]]:
              changes[3] === ''
                ? null
                : changes[3]?.toLowerCase() === 'yes' ||
                  changes[3]?.toLowerCase() === 'true'
                ? true
                : false,
            disagree:
              changes[3] === ''
                ? null
                : changes[3]?.toLowerCase() === 'yes' ||
                  changes[3]?.toLowerCase() === 'true'
                ? false
                : true,
          };

          const isAgreee = row.agree === true && row.disAgree === false;

          nextSheetData = updateSheetData(
            nextSheetData,
            row,
            isNewRow ? null : changes[0]
          );

          const valueInLowerase = newValue?.toLowerCase();
          if (
            valueInLowerase === 'yes' ||
            valueInLowerase === 'true' ||
            isAgreee
          ) {
            
            nextSheetData[rowIndex].audited_code = 
              nextSheetData[rowIndex].srvcs_no;
            nextSheetData[rowIndex].audited_rvu =
              nextSheetData[rowIndex].provider_rvu;
            nextSheetData[rowIndex].audited_dollar_value =
              nextSheetData[rowIndex].provider_dollar_value;
            nextSheetData = addColumnOnAgreeDisagree("srvcs_no", "audited_cpt", nextSheetData, rowIndex);
            nextSheetData = addColumnOnAgreeDisagree("billed_modifier", "audited_modifier", nextSheetData, rowIndex);
          }

          if (valueInLowerase === 'no') {
            nextSheetData[rowIndex].audited_code = null;
            // nextSheetData[rowIndex].audited_cpt = null;
            nextSheetData[rowIndex].audited_rvu = null;
            nextSheetData[rowIndex].audited_dollar_value = null;
            nextSheetData = addColumnOnAgreeDisagree("srvcs_no", "audited_cpt", nextSheetData, rowIndex, false);
            nextSheetData = addColumnOnAgreeDisagree("billed_modifier", "audited_modifier", nextSheetData, rowIndex, false);

          }

          break;
        case AUDIT_COLUMN_HEADER.DISAGREE:
          const disagreeRow = {
            [changes[1]]:
              changes[3] === ''
                ? null
                : changes[3]?.toLowerCase() === 'yes' ||
                  changes[3]?.toLowerCase() === 'true'
                ? true
                : false,
            agree:
              changes[3] === ''
                ? null
                : changes[3]?.toLowerCase() === 'yes' ||
                  changes[3]?.toLowerCase() === 'true'
                ? false
                : true,
          };

          const isDisagree =
            disagreeRow.disagree === true && disagreeRow.agree === false;

          nextSheetData = updateSheetData(
            nextSheetData,
            disagreeRow,
            isNewRow ? null : rowIndex
          );

          const _valueInLowerase = newValue?.toLowerCase();

          if (
            _valueInLowerase === 'yes' ||
            _valueInLowerase === 'true' ||
            isDisagree
          ) {
            nextSheetData[rowIndex].audited_code = null;
            nextSheetData[rowIndex].audited_cpt = null;
            nextSheetData[rowIndex].audited_rvu = null;
            nextSheetData[rowIndex].audited_dollar_value = null;
            nextSheetData = addColumnOnAgreeDisagree("srvcs_no", "audited_cpt", nextSheetData, rowIndex, false);
            nextSheetData = addColumnOnAgreeDisagree("billed_modifier", "audited_modifier", nextSheetData, rowIndex, false);
          }

          if (_valueInLowerase === 'no') {
            nextSheetData[rowIndex].audited_code =
              nextSheetData[rowIndex].srvcs_no;
            nextSheetData[rowIndex].audited_rvu =
              nextSheetData[rowIndex].provider_rvu;
            nextSheetData[rowIndex].audited_dollar_value =
              nextSheetData[rowIndex].provider_dollar_value;
            nextSheetData = addColumnOnAgreeDisagree("srvcs_no", "audited_cpt", nextSheetData, rowIndex);
            nextSheetData = addColumnOnAgreeDisagree("billed_modifier", "audited_modifier", nextSheetData, rowIndex);
          }

          break;
        case AUDIT_COLUMN_HEADER.ICD_AGREE: 
        case AUDIT_COLUMN_HEADER.ICD_DISAGREE:
          let editRow = handleAgreeDisagree(nextSheetData, changes);
          nextSheetData = updateSheetData(
            nextSheetData,
            editRow,
            isNewRow ? null : changes[0]
          );
          if (editRow.icd_agree) {
            nextSheetData = addColumnOnAgreeDisagree("billed_icd", "audited_icd", nextSheetData, rowIndex);
          }else {
            nextSheetData = addColumnOnAgreeDisagree("billed_icd", "audited_icd", nextSheetData, rowIndex, false);
          }
          break;
        case AUDIT_COLUMN_HEADER.ENC_DT:
          let data = changes[3];
          if (data) {
            const d = Date.parse(data);
            if (d) {
              const encDtRow1 = {
                [changes[1]]: format(new Date(d), 'MM-dd-yyyy'),
              };
              nextSheetData = updateSheetData(
                nextSheetData,
                encDtRow1,
                isNewRow ? null : changes[0]
              );
            } else {
              toast.showWarning({ description: 'Invalid Date: Use MM-DD-YY/YYYY format' });
            }
          } else {
            const encDtRow1 = {
              [changes[1]]: null,
            };
            nextSheetData = updateSheetData(
              nextSheetData,
              encDtRow1,
              isNewRow ? null : changes[0]
            );
          }

          break;
        case AUDIT_COLUMN_HEADER.RENDERING:
          const obj = providerOptions.find(
            p => p.value.toLowerCase() === changes[3].toLowerCase()
          );
          let renderingData = {
            [changes[1]]: null,
          };
          if (obj) {
            renderingData = {
              [changes[1]]: changes[3],
            };
          } else {
            toast.showError({
              description: 'This Rendering value is not available.',
            });
          }
          nextSheetData = updateSheetData(
            nextSheetData,
            renderingData,
            isNewRow ? null : changes[0]
          );

          break;
        default:
          const defaultRow = {
            [changes[1]]: changes[3] === '' ? null : changes[3],
          };
          nextSheetData = updateSheetData(
            nextSheetData,
            defaultRow,
            isNewRow ? null : changes[0]
          );

          break;
      }
    });

    const _change = change[0];
    const [rowIndex, columnName, oldValue, newValue] = _change;

    const hasAddedColumn = addColumn(columnName, nextSheetData, newValue, true);

    const isAdded = hasAddedColumn && Object.keys(hasAddedColumn).length;

    if (isAdded) {
      nextSheetData = nextSheetData.map(nsd => {
        if (nsd.id) {
          return {
            ...nsd,
            [hasAddedColumn.data]: null,
          };
        } else {
          return nsd;
        }
      });
    }

    setLocalSheetData(nextSheetData);
    //update audit sheet on first cell update in a row
    if (change.some(d => !sheetData[d[0]].id)) {
      updateAuditSheet({ ...sheet, data: nextSheetData });
    } else {
      updateSheet(nextSheetData);
    }
    return false;
  };

  const agreeValidator = (value, callback) => {
    setTimeout(() => {
      if (value.toLowerCase() === 'yes' || value === '-') {
        callback(true);
      } else {
        callback(false);
      }
    }, 1000);
  };

  const hasComment = (row, col) => {
    if (row < sheetData?.length) {
      const colData = auditSheetColumnsKeys[col];
      const rowId = sheetData[row]?.id;
      if (rowId && commentFlags && Object.keys(commentFlags).length) {
        if (commentFlags[rowId]?.includes(colData)) {
          return true;
        }
      }
    }
    return false;
  };

  // auditSheetHeaders
  function columnHeaders() {
    return auditSheetHeaders.map((header, i) => {
      const fontSize = getScaledHeaderFontSize(scale);
      return `<div style="font-size: ${fontSize};"class="header">${header}</div>`;
    });
  }

  function contextOption() {
    return {
      async callback(key, selection, clickEvent) {
        // Common callback for all options
        onCommentClick(
          selection,
          { pageX: clickEvent.screenX, pageY: clickEvent.screenY },
          key === 'comments',
          false
        );

        if (key === 'comments') {
          const { row, column } = getRowColArray(selection[0], sheet);
          await getComments(uploadId, {
            row,
            column,
          });
        }
      },
      items: {
        enable_missing_info: {
          name: '<span style="background-color: #ffe6e6; padding: 1px; border-radius: 3px;">Enable Missing Info</span>',
          callback: async function (key, selection) {
            let nextSheetData = [...sheetData];
            const temp = getRowColIndex(selection[0], sheet);
            const row = temp.rows[0];
            if (row >= 0 && row < nextSheetData.length) {
              if (nextSheetData[row].missing_info === null || nextSheetData[row].id === null) {
                nextSheetData[row].missing_info = true;
                if (nextSheetData[row].id === null) {
                  nextSheetData = updateSheetData(nextSheetData, {}, row)
                }
                await updateAuditSheet({ ...sheet, data: nextSheetData });
              } else {
                console.log("Missing Info is already true or row is empty.");
              }
            } else {
              console.log("Invalid row index.");
            }
          },
          hidden() {
            const selectedIndex = this.getSelectedLast()[0];
            return (
              selectedIndex < 0 ||
              selectedIndex >= sheetData.length ||
              sheetData[selectedIndex].missing_info === true
            );
          },
        },
        disable_missing_info: {
          name: '<span style="background-color: #e6ffe6; padding: 1px;  border-radius: 3px;">Disable Missing Info</span>',
          callback: async function (key, selection) {
            let nextSheetData = [...sheetData];
            const temp = getRowColIndex(selection[0], sheet);
            const row = temp.rows[0]
            if (row >= 0 && row < nextSheetData.length) {
              if (nextSheetData[row].missing_info !== null) {
                nextSheetData[row].missing_info = null;
                await updateAuditSheet();
              } else {
                console.log("Missing Info is already null or row is empty.");
              }
            } else {
              console.log("Invalid row index.");
            }
          },
          hidden() {
            const selectedIndex = this.getSelectedLast()[0];
            return (
              selectedIndex < 0 ||
              selectedIndex >= sheetData.length ||
              // sheetData[row].missing_info === null
              sheetData[selectedIndex].missing_info === null ||
              sheetData[selectedIndex].id === null
            );
          },
        },

        row_above: {
          name: () => '<p>Insert row above</p>',
          disabled() {
            return view;
          },
        },
        row_below: {
          disabled() {
            return view;
          },
        },
        remove_row: {
          callback: async function (key, selection) {
            const { rows } = getRowColIndex(selection[0], sheet);
            removeRows(rows);
            await updateAuditSheet();
          },
          disabled() {
            return view;
          },
        },
        cut: {
          disabled() {
            return view;
          },
        },
        copy: {
          disabled() {
            return view;
          },
        },
        freeze_column: {
          name: "Freeze Column",
          disabled() {
            return !view;
          },
        },
        unfreeze_column: {
          name: "Unfreeze Column",
          disabled() {
            return !view;
          },
        },
        export_to_csv: {
          name: "Export to CSV",
          callback(key, selection, clickEvent) {
              const hot = hotRef.current.hotInstance;
              hot.updateSettings({
                colHeaders: auditSheetHeaders
              });
              const exportPlugin = hot.getPlugin('exportFile');
              exportPlugin.downloadFile('csv', {
                bom: false,
                columnDelimiter: ',',
                columnHeaders: true,
                exportHiddenColumns: true,
                exportHiddenRows: true,
                fileExtension: 'csv',
                filename: currentUpload.upload_id,
                mimeType: 'text/csv',
                rowDelimiter: '\r\n',
                rowHeaders: false
              });
              
            },
        },
        comments: {
          name: 'Comments',
          hidden() {
            // `hidden` can be a boolean or a function
            // Hide the option when the first column was clicked
            const selectedIndex = this.getSelectedLast()[2];
            return !sheetData[selectedIndex].id; // `this` === hot
          },
        },
      },
    };
  }

  function onKeyPress(e) {
    let col = this.getSelectedLast()[1];
    var evt = e || window.event; // IE support
    var key = evt.charCode || evt.keyCode || 0;

    // check for cut and paste
    var isClipboard = false;
    var ctrlDown = evt.ctrlKey || evt.metaKey; // Mac support

    // Check for Alt+Gr (http://en.wikipedia.org/wiki/AltGr_key)
    if (ctrlDown && evt.altKey) isClipboard = false;
    // Check for ctrl+c, v and x
    else if (ctrlDown && key === 67) isClipboard = true; // c
    else if (ctrlDown && key === 86) isClipboard = true; // v
    else if (ctrlDown && key === 88) isClipboard = true; // x
    else if (ctrlDown && evt.key === 'z') isClipboard = true;
    const isNumeric =
      NUMERIC_KEYS.includes(key) ||
      (key >= 35 && key <= 40) ||
      (key >= 48 && key <= 57) ||
      (key >= 96 && key <= 105);

    switch (col) {
      case 3:
        // Shift movement and copy-paste are not enabled for column 3 (Enc Date).
        if ((!isNumeric && !isClipboard) || e.shiftKey) {
          // prevent alpha characters
          e.stopImmediatePropagation();
          e.preventDefault();
        }
        break;
      default:
        break;
    }

    numericColumns.forEach(nc => {
      switch (col) {
        case nc:
          if ((!isNumeric && !isClipboard) || e.shiftKey) {
            // prevent alpha characters
            e.stopImmediatePropagation();
            e.preventDefault();
          }
          break;
        default:
          break;
      }
    });
  }

  function onPaste(data, coords) {
    const [startRow, startCol] = [coords[0].startRow, coords[0].startCol];
    const actualData = data[0];

    const rvuIndices = columnData.findIndex(col => col.data === 'provider_rvu');
    const isRvuColumn = startCol === rvuIndices;

    // Check if the destination column is 'enc_dt'
    if (isRvuColumn) {
      // Check if any of the pasted values is not a number
      if (actualData.some(value => isNaN(+value))) {
        return false;
      }
    }

    // Continue with the paste operation for other columns or valid numeric values
    return true;
  }

  function _afterOnCellMouseDown(e, p) {
    // e - event , p - cellCords[ row , column ]
    closeComment();
    const rowId = sheetData[p.row]?.id;
    if (p.col === RESPONSE_AS_COL && rowId) {
      onCommentClick(
        [
          {
            start: { row: p.row, col: p.col },
            end: { row: p.row, col: p.col },
          },
        ],
        { pageX: e.screenX + 100, pageY: e.screenY + 100 },
        false,
        true
      );
    } else {
      onCommentClick(
        [
          {
            start: { row: p.row, col: p.col },
            end: { row: p.row, col: p.col },
          },
        ],
        { pageX: e.screenX + 100, pageY: e.screenY + 100 },
        false,
        false
      );
    }
  }

  function cellProps(row, col) {
    var cellProperties = {};

    const COL = highlightedColumn; // [4, 10];
    if (COL.includes(col)) {
      cellProperties.renderer = columnHighlight; // uses lookup map
    }
    if (hasComment(row, col)) {
      cellProperties.renderer = commentCell; // uses comment map
    }

    // Check for missing_info and apply background color
    const rowData = this.instance.getSourceDataAtRow(row);
    if (rowData && rowData.missing_info) {
      cellProperties.className = 'missing-info';
    }

    return cellProperties;
  }

  /** @type {import('handsontable/settings').GridSettings} */
  const hotSettings = {
    data: sheetData,

    height: height,

    colHeaders: columnHeaders(),

    minSpareRows: 10,

    licenseKey: 'non-commercial-and-evaluation',

    //  right-click or context menu
    contextMenu: contextOption(),

    beforeChange: onBeforeHotChange,

    beforeKeyDown: onKeyPress,

    outsideClickDeselects: false,

    afterOnCellMouseDown: _afterOnCellMouseDown,

    cells: cellProps,

    beforePaste: onPaste,
  };
  /** @type {import('handsontable/settings').ColumnSettings[]} */
  const auditSheetColumnData = [
    {
      data: 'encounter_no',
      width: windowSize * 0.07,
      readOnly: view,
      renderer: 'customStylesRenderer',
    },
    {
      data: 'rendering',
      type: 'audit_sheet.dropdown_renderer',
      width: windowSize * 0.07,
      source: providerOptions.map(p => p.value),
      readOnly: view,
    },
    {
      data: 'enc_dt',
      type: 'date',
      dateFormat: 'MM-DD-YYYY',
      width: windowSize * 0.07,
      readOnly: view,
      renderer: 'customStylesRenderer',
      datePickerConfig: {
        // First day of the week (0: Sunday, 1: Monday, etc)
        disableDayFn(date) {
          // Disable Sunday and Saturday
          return date > new Date();
        },
      },
    },
    {
      data: 'srvcs_no',
      type: 'text',
      width: windowSize * 0.07,
      readOnly: view,
      renderer: 'customStylesRenderer',
    },
    {
      data: 'provider_rvu',
      type: 'numeric',
      width: windowSize * 0.07,
      readOnly: view,
      renderer: 'customStylesRenderer',
    },
    {
      data: 'provider_dollar_value',
      type: 'audit_sheet.currency_renderer',
      width: windowSize * 0.07,
      readOnly: view || isProviderDollarDisabled,
    },
    {
      data: 'response',
      type: 'text',
      width: windowSize * 0.15,
      readOnly: view,
      renderer: 'customStylesRenderer',
    },
    {
      data: 'agree',
      type: 'text',
      validator: agreeValidator,
      width: windowSize * 0.07,
      readOnly: view,
      renderer: 'agreeRenderer',
    },
    {
      data: 'disagree',
      type: 'text',
      validator: agreeValidator,
      width: windowSize * 0.07,
      readOnly: view,
      renderer: 'agreeRenderer',
    },
    {
      data: 'audited_code',
      type: 'text',
      width: windowSize * 0.07,
      readOnly: view,
      renderer: 'customStylesRenderer',
    },
    {
      data: 'audited_rvu',
      type: 'numeric',
      width: windowSize * 0.07,
      readOnly: view,
      renderer: 'customStylesRenderer',
    },
    {
      data: 'audited_dollar_value',
      width: windowSize * 0.07,
      type: 'audit_sheet.currency_renderer',
      readOnly: view || isAuditedDollarDisabled,
    },
    {
      data: 'notes',
      type: 'text',
      width: windowSize * 0.15,
      readOnly: view,
      renderer: 'customStylesRenderer',
    },
  ];

  function addColumnOnAgreeDisagree(billedFieldName, auditFieldName, nextSheetData, rowIndex, isAgree=true){
    //function for adding or removing column and value when we Agree or disagree
    let isAdded = false;
    let hasAddedColumn = null;
    for (let key in nextSheetData[rowIndex]) {
      // Check if the key starts with "srvcs_no"
      if(isAgree){
        isAdded = false;
        hasAddedColumn = null;
        if (key.startsWith(billedFieldName)) {
          if(key === billedFieldName){
            nextSheetData[rowIndex][auditFieldName] = nextSheetData[rowIndex][billedFieldName];

            let nextCloumnName = createColumnDataName(key);
            if(nextSheetData[rowIndex].hasOwnProperty(nextCloumnName)){
              hasAddedColumn = addColumn(auditFieldName, nextSheetData);
              isAdded = hasAddedColumn && Object.keys(hasAddedColumn).length;
            }
          }
          else{
            const srvcs_parts = key.split('_');
            nextSheetData[rowIndex][auditFieldName+"_"+srvcs_parts[2]] = nextSheetData[rowIndex][key];
            
            let nextCloumnName = createColumnDataName(key);
            if(nextSheetData[rowIndex].hasOwnProperty(nextCloumnName)){
              hasAddedColumn = addColumn(auditFieldName+"_"+srvcs_parts[2], nextSheetData);
              isAdded = hasAddedColumn && Object.keys(hasAddedColumn).length;
            }
            
          }
          if (isAdded) {
            nextSheetData = nextSheetData.map(nsd => {
              if (nsd.id) {
                return {
                  ...nsd,
                  [hasAddedColumn.data]: null,
                };
              } else {
                return nsd;
              }
            });
          }
        }
      }else{
        if (key.startsWith(auditFieldName)) {
          nextSheetData[rowIndex][key] = null;
        }
      }
    }
    return nextSheetData;
  }

  function addColumn(columnName, nextSheetData, newValue=null, checkhasValue=false) {
    // const [row, column, oldValue, newValue] = changes[0];
    const column = columnName

    

    let haveValue = false;
    if (newValue) {
      haveValue = true;
    }

    const _selectedIndex = columnData.findIndex(cd => cd.data === column);

    const selectedColumnIndex = _selectedIndex;

    const isEmptyColumn = hotRef.current?.hotInstance?.isEmptyCol(
      column
    );

    const newColumnHeader = auditSheetHeaders[selectedColumnIndex];
    const selectedColumnData = columnData[selectedColumnIndex];

    const _newColumnHeader = creatColumnHeader(newColumnHeader);

    const newColumnName = createColumnDataName(column);

    const newColumnData = {
      data: newColumnName,
      type: 'text',
      width: windowSize * 0.15,
      readOnly: view,
      renderer: 'customStylesRenderer',
    };

    let existingHeaders = auditSheetHeaders;
    let existingColumnData = columnData;

    const colAlreadyExist = nextSheetData.find(cd => {
      const hasAreadyxist = cd.hasOwnProperty(newColumnName);

      if (hasAreadyxist && cd.id) {
        return cd;
      }
    });

    function _addColumn() {
      const canAddColumn = isColumnMatched(column, dynamicColumnKeywords);

      if (canAddColumn) {
        existingHeaders.splice(selectedColumnIndex + 1, 0, _newColumnHeader);

        existingColumnData.splice(selectedColumnIndex + 1, 0, newColumnData);

        setLocalHeader(existingHeaders);
        setLocalColumnData(existingColumnData);

        return newColumnData;
      }

      return null;
    }
    if(checkhasValue){
      if (isEmptyColumn && haveValue && !colAlreadyExist) {
        return _addColumn();
      }
    }else{
      if (isEmptyColumn && !colAlreadyExist) {
        return _addColumn();
      }
    }
  }
  
  return (
    <HotTable
      ref={hotRef}
      columns={columnData}
      data={sheetData}
      settings={{
        ...hotSettings,
        rowHeights: getScaledRowHeight(scale),
        stretchH: 'all',
        autoColumnSize: false,
        autoRowSize: false,
        wordWrap: true,
        rowHeaders: true,
        manualColumnFreeze: true,
      }}
    ></HotTable>
  );
};
export default AuditSheet;
