import { API } from '../config/AppConfig';
import { GET_FAQ } from '../constants/Endpoints';

class FaqHelpRepository {
  async getFaqQuestions() {
    const response = await API.get(GET_FAQ);
    return response;
  }
}

const faqHelpRepository = new FaqHelpRepository();
export default faqHelpRepository;
