import { useContext } from "react";
import { Mention, MentionsInput } from "react-mentions";
import AppColors from "../../../../constants/AppColors";
import { AuditSheetContext } from "../../../../providers/AuditSheetProvider";
import { defaultStyle } from "../../constants";

const CommentInput = ({ message, setMessage, placeholder, id }) => {

    const auditSheetContext = useContext(AuditSheetContext);
    const { users } =
        auditSheetContext;

    return <MentionsInput
        id={id}
        placeholder={placeholder ? placeholder : 'Comment or tag others with @'}
        value={message}
        onChange={e => {
            setMessage(e.target.value);
        }}
        onClick={(e) => {
            e.stopPropagation()
        }}
        style={defaultStyle}
        allowSpaceInQuery={true}
    >
        <Mention
            displayTransform={login => {
                const currentUser = users.find(u => Number(u.id) === Number(login))
                return `@${currentUser.first_name} ${currentUser.last_name}`
            }}
            trigger="@"
            data={users}
            markup="@[__id__]"
            style={{
                fontWeight: '50px',
                backgroundColor: AppColors.bgColor
            }}
        />
    </MentionsInput>
}
export default CommentInput