import BlankLayout from '../components/layout/BlankLayout';
import ROUTES from '../constants/Routes';
import AuditSheetContainer from '../pages/audit_sheet/AuditSheetContainer';
import DashboardContainer from '../pages/dashboard/DashboardContainer';
import HelpContainer from '../pages/faq/HelpContainer';
import Notifications from '../pages/notifications/Notifications';
import AccountContainer from '../pages/profile/AccountContainer';

const AppRouter = [
  {
    route: ROUTES.DASHBOARD,
    element: <DashboardContainer />,
  },
  {
    route: ROUTES.ACCOUNT,
    element: <AccountContainer />,
  },
  {
    route: ROUTES.AUDIT_SHEET,
    element: <AuditSheetContainer />,
    layout: BlankLayout,
  },
  {
    route: ROUTES.VIEW_AUDIT_SHEET,
    element: <AuditSheetContainer view={true} />,
    layout: BlankLayout,
  },
  {
    route: ROUTES.NOTIFICATIONS,
    element: <Notifications />,
  },
  {
    route: ROUTES.HELP,
    element: <HelpContainer />,
  },
];

export default AppRouter;
