import { Button, Center, Flex, Modal, ModalBody, ModalContent, ModalHeader, ModalOverlay, useMediaQuery } from "@chakra-ui/react";
import { useContext } from "react";
import AppColors from "../../../constants/AppColors";
import { FONT_FAMILY } from "../../../constants/Theme";
import { AuditSheetContext } from "../../../providers/AuditSheetProvider";

const SubmitModal = (props) => {
    const auditSheetContext = useContext(AuditSheetContext)
    const { isAuditSheetLoading } = auditSheetContext
    const [isNotSmallScren] = useMediaQuery('(min-width: 410px)');
    return (
        <>
            <Modal
                isOpen={props.isOpen}
                size={'lg'}
                onClose={props.onClose}
                isCentered
            >
                <ModalOverlay style={{ backgroundColor: AppColors.lightSkyBlue }} />
                <ModalContent
                    p={15}
                    alignItems={'center'}
                >
                    <ModalHeader>
                        <Center fontSize={'xl'}
                            color={AppColors.secondary}
                            fontFamily={FONT_FAMILY.baiJamurjee}
                            fontWeight='bold'>
                            Are you sure you’re ready to
                        </Center>
                        <Center fontSize={'xl'}
                            color={AppColors.secondary}
                            fontFamily={FONT_FAMILY.baiJamurjee}
                            fontWeight='bold'>
                            submit this audit to QA review?
                        </Center>
                    </ModalHeader>
                    <ModalBody mb={2}>
                        <Flex
                            alignItems={'center'}
                            direction={isNotSmallScren ? 'row' : 'column'}
                        >
                            <Button
                                variant="outline"
                                mr={0}
                                borderRadius={25}
                                fontWeight={500}
                                outline={'2px solid #023B93'}
                                paddingInline={'35px'}
                                backgroundColor={AppColors.white}
                                marginBottom={isNotSmallScren ? '0px' : '10px'}
                                onClick={() => {
                                    props.onClose()
                                }}
                                fontSize={'sm'}

                            >
                                No, Keep Auditing
                            </Button>
                            <Button
                                ml={isNotSmallScren ? 5 : 0}
                                borderRadius={25}
                                color={AppColors.white}
                                paddingInline={'35px'}
                                backgroundColor={AppColors.primary}
                                fontWeight='normal'
                                fontSize={'sm'}
                                disabled={isAuditSheetLoading}
                                isLoading={isAuditSheetLoading}
                                onClick={() => {
                                    props.onAuditSubmit()
                                }}
                            >
                                Yes, Submit
                            </Button>
                        </Flex>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
}
export default SubmitModal