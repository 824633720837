/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  HStack,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react';
import {
  Heading,
  TextInput,
  useCQMediaQuery,
  useTabStorage,
} from '@laxmimanogna/code-quick-components';
import React, { useContext, useEffect, useState } from 'react';
import CQContainer from '../../components/layout/CQContainer';
import { FONT_FAMILY } from '../../constants/Theme';
import MyAuditProvider, {
  MyAuditContext,
} from '../../providers/MyAuditProvider';
import RecentAudits from './components/RecentAudits';
import { withProvider } from '../../hoc/withProvider';
import AllAuditTable from './components/table/AllAuditTable';
import NewUploads from './components/table/NewUploads';
import ArchivedTable from './components/table/ArchivedTable';
import RebuttalTable from './components/table/RebuttalTable';
import Urgent from './components/table/Urgent';
import AppColors from '../../constants/AppColors';
import { SearchIcon } from '@chakra-ui/icons';
import { UrgentIcon } from '../../constants/IconData';
import { CustomTab } from '../../components/CustomTab';

const DashboardContainer = props => {
  // states
  const [onSearchText, setSearchText] = useState('');
  const tabStorage = useTabStorage();
  const [selectedTab, setSelectedTab] = useState(tabStorage.getTab() || 0);

  useEffect(() => {tabStorage.setTab(selectedTab)}, [selectedTab]);

  // other hooks
  const marginHorizontal = useCQMediaQuery({
    lg: '12%',
    md: '6%',
    sm: '2%',
  });

  // context
  const myAuditContext = useContext(MyAuditContext);
  const {
    auditUrgentListCount,
    getRecentAudits,
    handleApplyFilter,
    filterParams,
  } = myAuditContext;

  const onSearchAction = () => {
    handleApplyFilter({ ...filterParams, searchText: onSearchText });
  };

  const onFilterChange = e => {
    if (e) {
      myAuditContext.getAuditUpload.setParams({ page: 1 });
      myAuditContext.getAuditNewUploads.setParams({ page: 1 });
      myAuditContext.getAuditArchived.setParams({ page: 1 });
      myAuditContext.getAuditRebuttals.setParams({ page: 1 });
      myAuditContext.getAuditUrgent.setParams({ page: 1 });
      setSearchText(e.target.value);
    } else {
      setSearchText('');
    }
  };

  const auditorPortalTabs = [
    {
      title: 'ALL',
      header: 'Chart Uploads',
      component: AllAuditTable,
    },
    {
      title: 'NEW UPLOADS',
      header: 'New Uploads',
      component: NewUploads,
    },
    {
      title: 'ARCHIVED',
      header: 'Archived',
      component: ArchivedTable,
    },
    {
      title: 'REBUTTALS',
      header: 'Chart Uploads',
      component: RebuttalTable,
    },
    {
      title: 'URGENT',
      header: 'Urgent',
      component: Urgent,
    },
  ];

  // life-cycle hooks
  useEffect(() => {
    getRecentAudits();
  }, []);

  return (
    <Box>
      <Box py={5} height={250} bg={'white'} px={marginHorizontal}>
        <Heading
          fontSize={'24px'}
          fontFamily={FONT_FAMILY.baiJamurjee}
          fontWeight="bold"
        >
          Recent Audits
        </Heading>
        <RecentAudits />
      </Box>
      <CQContainer mx={marginHorizontal}>
        <Heading my={5} fontSize={'xl'}>
          {auditorPortalTabs[selectedTab].header}
        </Heading>
        <Box
          shadow={'md'}
          bg="white"
          p={4}
          w="100%"
          style={{ borderRadius: '12px' }}
        >
          <Tabs
            fontFamily={FONT_FAMILY.baiJamurjee}
            onChange={setSelectedTab}
            index={selectedTab}
          >
            <TabList>
              <HStack w={'100%'} justifyContent={'space-between'}>
                <HStack spacing={4} px={4}>
                  {auditorPortalTabs.map((apt, i) => {
                    return (
                      <Box position={'relative'}>
                        <CustomTab
                          key={`assigned-audit-tab-${i}`}
                          color={AppColors.secondary}
                          fontFamily={FONT_FAMILY.baiJamurjee}
                        >
                          {apt.title}
                          {apt.title === 'URGENT' &&
                          auditUrgentListCount > 0 ? (
                            <UrgentIcon
                              style={{ position: 'absolute', left: 90, top: 2 }}
                            />
                          ) : null}
                        </CustomTab>
                      </Box>
                    );
                  })}
                </HStack>
                <Box>
                  <TextInput
                    borderRadius={'md'}
                    value={onSearchText}
                    onChange={e => onFilterChange(e)}
                    onKeyDown={e => {
                      if (e.keyCode === 13) {
                        onSearchAction();
                      }
                    }}
                    mb="2"
                    rightIconProps={{ zIndex: 0 }}
                    placeholder="Search"
                    rightIcon={<SearchIcon onClick={onSearchAction} />}
                  />
                </Box>
              </HStack>
            </TabList>

            <TabPanels>
              {auditorPortalTabs.map((apt, i) => {
                return (
                  <TabPanel p={0} key={`assigned-audit-panel-${i}`}>
                    <apt.component />
                  </TabPanel>
                );
              })}
            </TabPanels>
          </Tabs>
        </Box>
      </CQContainer>
    </Box>
  );
};

export default withProvider(MyAuditProvider, DashboardContainer);
