import { useBoolean } from '@laxmimanogna/code-quick-components';
import { createContext, useContext } from 'react';
import userManagementRepository from '../repositories/UserManagementRepository';
import { useToastr } from '@laxmimanogna/code-quick-components';
import { useNavigate } from 'react-router-dom';
import ROUTES from '../constants/Routes';
import { successToast } from '../components/successToast';
import { AuthContext } from './AuthProvider';

export const UserManagementContext = createContext({
  isUserLoading: false,
  updateUser: async () => {},
});

const UserManagementProvider = props => {
  const authContext = useContext(AuthContext);
  const navigate = useNavigate();
  const toast = useToastr();

  //loading state
  const [isUserLoading, isuState] = useBoolean(false);

  //upadate the current user
  async function updateUser(updateParams) {
    try {
      isuState.on();
      const response = await userManagementRepository.updateUser(updateParams);
      const toastProps = {
        header: 'Success!',
        description: `${'User updated successfully.'}`,
      };
      successToast(toast, toastProps);

      navigate(ROUTES.DASHBOARD);
      authContext.updateCurrentUser(response);
      return response;
    } catch (e) {
      const response = e.cause;
      if (
        response &&
        response?.errors &&
        Object.keys(response?.errors).length
      ) {
        const keys = Object.values(response.errors);
        const descriptionData =
          keys && keys.length && keys.map(key => `${key}, `);
        toast.showError({
          description: descriptionData,
        });
        return { errors: response.errors };
      }
    } finally {
      isuState.off();
    }
  }

  const mContext = {
    isUserLoading,
    updateUser,
  };

  return (
    <UserManagementContext.Provider value={mContext}>
      {props.children}
    </UserManagementContext.Provider>
  );
};

export default UserManagementProvider;
