import { REQUIRED_FIELDS } from "../../../constants/constants";

const submitRule = (sheets) => {
    return sheets.some(currentSheet => {
        const canSubmit = currentSheet?.data?.length ? currentSheet.data.every(element => {
            if (element.missing_info) {
                // If missing_info is true, only encounter_no and rendering is mandatory
                return element.encounter_no !== null && element.encounter_no !== "" && element.rendering !== null && element.rendering !== "";
            } else {
                // If missing_info is false, validate all REQUIRED_FIELDS
                const headers = Object.keys(element);
                const data = REQUIRED_FIELDS.every(key => {
                    if (headers.includes(key) && element[key] !== null && element[key] !== "") {
                        return true;
                    }
                    return false;
                });
                return data;
            }
        }) : true;
        return !canSubmit;
    });
};

export { submitRule }

