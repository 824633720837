import Handsontable from 'handsontable';
import AppColors from '../../../../constants/AppColors';

class AuditSheetEditor extends Handsontable.editors.NumericEditor {
  init() {
    super.init();
  }
}

function getScaledRowFontSize(scale) {
  return 14 * scale + 'px';
}

function customDropdownRenderer(
  instance,
  td,
  row,
  col,
  prop,
  value,
  cellProperties
) {
  td.style.textAlign = 'center';
  td.style.fontSize = getScaledRowFontSize(cellProperties?.scale);
  Handsontable.renderers.AutocompleteRenderer(
    instance,
    td,
    row,
    col,
    prop,
    value,
    cellProperties
  );
}

function currencyRenderer(instance, td, row, col, prop, value, cellProperties) {
  if (value && !''.startsWith('$')) {
    value = `$${value}`;
  }

  td.style.textAlign = 'center';
  td.style.fontSize = getScaledRowFontSize(cellProperties?.scale);
  td.style.background = AppColors.lightPink;
  Handsontable.renderers.TextRenderer(
    instance,
    td,
    row,
    col,
    prop,
    value,
    cellProperties
  );
}

export function registerCustomCells({ scale }) {
  Handsontable.cellTypes.registerCellType('audit_sheet.currency_renderer', {
    editor: AuditSheetEditor,
    renderer: currencyRenderer,
    validator: Handsontable.validators.NumericValidator,
    // You can add additional options to the cell type
    // based on Handsontable settings
    allowInvalid: false,
    type: 'numeric',
    scale: scale,
  });

  Handsontable.cellTypes.registerCellType('audit_sheet.dropdown_renderer', {
    editor: Handsontable.editors.DropdownEditor,
    renderer: customDropdownRenderer,
    validator: Handsontable.validators.AutocompleteValidator,
    // You can add additional options to the cell type
    // based on Handsontable settings
    allowInvalid: false,
    scale: scale,
  });

  Handsontable.cellTypes.registerCellType('audit_sheet.currency_renderer', {
    editor: AuditSheetEditor,
    renderer: currencyRenderer,
    validator: Handsontable.validators.NumericValidator,
    // You can add additional options to the cell type
    // based on Handsontable settings
    allowInvalid: false,
    type: 'numeric',
    scale: scale,
  });

  // custom styles for cells
  Handsontable.renderers.registerRenderer(
    'customStylesRenderer',
    (hotInstance, TD, ...rest) => {
      Handsontable.renderers.getRenderer('text')(hotInstance, TD, ...rest);
      TD.style.fontSize = getScaledRowFontSize(scale);
      TD.style.textAlign = 'center';
    }
  );

  // custom styles for notes
  Handsontable.renderers.registerRenderer(
    'audit_sheet.notes_renderer',
    (hotInstance, TD, row, column, prop, value, cellProperties) => {
      Handsontable.renderers.getRenderer('text')(
        hotInstance,
        TD,
        row,
        column,
        prop,
        value,
        cellProperties
      );
      if (value != null && (prop === 'notes' || prop === 'response')){
        if(cellProperties.readOnly){
          TD.innerHTML = value
          TD.style.whiteSpace="nowrap"
          TD.style.overflow = "hidden"
          TD.style.textOverflow= "ellipsis"
          TD.classList.add('expandable-notes-cell');
          TD.addEventListener('dblclick', () => {
            TD.style.whiteSpace="normal"
          });
        }else{
          TD.innerText = value
        }
        if(prop === 'response'){
          TD.style.textAlign = 'center';
        }
      }
    }
  );

  Handsontable.renderers.registerRenderer(
    'customStylesRendererCheckbox',
    (hotInstance, TD, row, column, prop, value, cellProperties) => {
      Handsontable.renderers.getRenderer('checkbox')(hotInstance, TD, row, column, prop, value, cellProperties);
      TD.style.fontSize = getScaledRowFontSize(scale);
      TD.style.textAlign = 'center';
    }
  );

  Handsontable.renderers.registerRenderer(
    'cptIcdStylerRenders',
    (hotInstance, TD, row, column, prop, value, cellProperties) => {
      Handsontable.renderers.getRenderer('numeric')(
        hotInstance,
        TD,
        row,
        column,
        prop,
        value,
        cellProperties
      );
      TD.style.fontSize = getScaledRowFontSize(scale);
      TD.style.textAlign = 'center';
      if (value !== null && value !== false && typeof value === 'string') {
        const dotIndex = value?.indexOf('.');
        if (dotIndex !== -1) {
          TD.innerHTML = value.slice(0, dotIndex + 6);
        } else {
          TD.innerHTML = value;
        }
      }
    }
  );

  Handsontable.renderers.registerRenderer(
    'agreeRenderer',
    (hotInstance, TD, row, column, prop, value, cellProperties) => {
      Handsontable.renderers.getRenderer('text')(
        hotInstance,
        TD,
        row,
        column,
        prop,
        value,
        cellProperties
      );
      TD.style.fontSize = getScaledRowFontSize(scale);
      TD.style.textAlign = 'center';
      if (value != null) {
        TD.innerHTML = value === true || value === 'true' ? 'Yes' : '-';
      }
    }
  );
}
