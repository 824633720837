/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Box, HStack, Text } from '@chakra-ui/react';
import { MyAuditContext } from '../../../../providers/MyAuditProvider';
import { createColumnHelper } from '@tanstack/react-table'; //package installed using yarn -for table
import { format, intlFormatDistance } from 'date-fns';
import { RebuttalIcon } from '../../../../constants/IconData';
import {
  CQReactTable,
  useCQMediaQuery,
} from '@laxmimanogna/code-quick-components';
import {
  defaultReactTableProp,
  paginationProps,
} from '../../../../utils/my_audits.utils';
import { renderActionComponent } from '../DashboardComponents';
import { useNavigate } from 'react-router-dom';
import {
  getAuditStatus,
  MY_AUDITS_ORDERING,
} from '../../../../constants/constants';
import { toTitleCase } from '../../../../utils/common.utils';
import FileViewAction from '../FileViewAction';

const columnHelper = createColumnHelper();

function RebuttalTable() {
  const auditContext = useContext(MyAuditContext);
  const navigate = useNavigate();
  const [ordering, setOrdering] = useState('');
  const { getAuditRebuttals } = auditContext;

  useEffect(() => {
    let searching = !!(auditContext.filterParams.searchText && auditContext.filterParams.searchText.trim());
    getAuditRebuttals.loadAPI("Auditor", "Rebuttal Audits", searching);
  }, [auditContext.filterParams, getAuditRebuttals.showOrdering]);

  useEffect(() => {
    if (MY_AUDITS_ORDERING.includes(ordering)) {
      getAuditRebuttals.setParams({ ordering });
    }
  }, [ordering]);

  const canScroll = useCQMediaQuery({
    lg: false,
    md: false,
    sm: true,
  });

  let columns = useMemo(
    () => [
      columnHelper.accessor('upload_id', {
        header: () => '',
        id: 'urgent_column',
        enableSorting: false,
        cell: info => {
          return (
            <HStack
              textAlign={'right'}
              justifyContent={'flex-end'}
              alignItems={'end'}
            >
              <RebuttalIcon style={{ height: 20, width: 20 }} />
            </HStack>
          );
        },
      }),

      columnHelper.accessor('upload_id', {
        header: () => 'Upload #',
        id: 'chart_id',
        cell: info => {
          return (
            <HStack>
              <Text fontSize={16} fontWeight={'bold'}>
                {info.renderValue()}
              </Text>
            </HStack>
          );
        },
      }),

      columnHelper.accessor(row => row.uploaded_date, {
        id: 'upload_date',
        header: () => 'Chart Upload',
        cell: info => {
          const cellValue = info.getValue();
          if (!cellValue) {
            return 'N/A';
          }
          return (
            <HStack justifyContent={'flex-start'}>
              <Text pl={3} fontSize={16} fontWeight={'bold'}>
                {format(new Date(cellValue), 'MM/dd/yyyy')}
              </Text>
            </HStack>
          );
        },
      }),

      columnHelper.accessor('audited_date', {
        header: () => 'Audit Upload',
        id: 'audited_date',
        cell: info => {
          const cellValue = info.getValue();
          if (!cellValue) {
            return 'N/A';
          }
          return (
            <HStack justifyContent={'center'}>
              <Text pl={4} fontSize={16} fontWeight={'bold'}>
                {format(new Date(cellValue), 'MM/dd/yyyy')}
              </Text>
            </HStack>
          );
        },
      }),

      columnHelper.accessor(p => p.specialties?.name, {
        header: () => 'Specialties',
        id: 'specialty__name',
        cell: info => {
          const specialties = info.row.original.specialties?.name;

          if (!specialties) {
            return 'N/A';
          }

          return specialties === 'ent' ? 'ENT' : toTitleCase(specialties)

          // return (
          //   <HStack justifyContent={'flex-start'}>
          //     <Text pl={4} fontSize={16} fontWeight={'bold'}>
          //       {specialties === 'ent' ? 'ENT' : toTitleCase(specialties)}
          //     </Text>
          //   </HStack>
          // );
        },
      }),

      columnHelper.accessor(p => p.assigned_qa?.first_name, {
        header: () => ' QA',
        id: 'qa',
        cell: info => {
          const qaName = info.row.original.assigned_qa
            ? `${info.row.original.assigned_qa.first_name} ${info.row.original.assigned_qa.last_name}`
            : 'N/A';
          return (
            <Text fontSize={16} fontWeight={'bold'}>
              {toTitleCase(qaName)}
            </Text>
          );
        },
      }),

      columnHelper.accessor('status', {
        header: () => 'Status',
        id: 'status',
        cell: info => {
          return (
            <Text fontSize={16} fontWeight={'bold'}>
              {getAuditStatus(info.row.original.status)}
            </Text>
          );
        },
      }),

      columnHelper.accessor('updated_at', {
        header: () => 'Last Updated',
        id: 'updated_date',
        cell: info => {
          let updatedAt = info.getValue();

          updatedAt = updatedAt
            ? intlFormatDistance(new Date(updatedAt), new Date(), {
                addSuffix: true,
              })
            : 'N/A';

          return (
            <Text fontSize={16} fontWeight={'bold'}>
              {updatedAt !== 'N/A' ? toTitleCase(updatedAt) : updatedAt}
            </Text>
          );
        },
      }),

      columnHelper.accessor('actions', {
        header: () => 'Actions',
        enableSorting: false,
        cell: info => {
          return renderActionComponent(
            info.row.original,
            navigate,
            auditContext
          );
        },
      }),
      columnHelper.accessor('actions', {
        header: () => '',
        enableSorting: false,
        cell: info => {
          const auditUpload = info.row.original;
          return <FileViewAction auditUpload={auditUpload} />;
        },
      }),
    ],
    [auditContext.isAuditUploadListLoading]
  );

  function handlePreviousPage() {
    auditContext.getAuditRebuttals.loadPrevious("Auditor", "Rebuttal Audits");
  }

  function handleNextPage() {
    auditContext.getAuditRebuttals.loadNext("Auditor", "Rebuttal Audits");
  }

  function handleSizeChange(size) {
    auditContext.getAuditRebuttals.loadSize("Auditor", "Rebuttal Audits", size );
  }

  function handlePageChangeByNumber(pageNumber) {
    auditContext.getAuditRebuttals.loadPageByNumber("Auditor", "Rebuttal Audits", pageNumber);
  }

  return (
    <>
      <Box
        // pointerEvents={isUpdating ? 'none' : 'inherit'}
        overflowX={canScroll ? 'scroll' : 'inherit'}
      >
        <CQReactTable
          manualSortBy={true}
          isLoading={auditContext.isAuditUploadListLoading}
          data={auditContext.auditRebuttalsList}
          columns={columns}
          {...defaultReactTableProp}
          {...paginationProps(auditContext.getAuditRebuttals)}
          onPreviousPage={handlePreviousPage}
          onNextPage={handleNextPage}
          onSizeChange={handleSizeChange}
          onPageChangeByNumber={handlePageChangeByNumber}
          onAsc={header => {
            setOrdering(header);
          }}
          onDesc={header => {
            setOrdering(`-${header}`);
          }}
        />
      </Box>
    </>
  );
}

export default RebuttalTable;

//  getRowProps={row => ({
// style: { backgroundColor: 'green' },
// })}
