const ROUTES = {
  // AUTH
  LOGIN_SCREEN: '/auth/login',
  ROOT_SCREEN: '/',
  FORGOT_PASSWORD_SCREEN: '/auth/forgot-password',
  CONFIRMATION_SCREEN: '/auth/forgot-password-message',
  RESET_PASSWORD_SCREEN: '/auth/reset-password',
  RESET_CONFIRMATION_SCREEN: '/auth/reset-password-message',

  // PRIVATE ROUTES
  DASHBOARD: '/dashboard',

  // ACCOUNT ROUTE
  ACCOUNT: '/account',

  AUDIT_SHEET: '/audit/sheet/:uploadId',
  VIEW_AUDIT_SHEET: '/audit/sheet/:uploadId/view',

  NOTIFICATIONS: '/client/notification',
  HELP: '/client/help',
};

export default ROUTES;

export const PUBLIC_PATH = [
  ROUTES.LOGIN_SCREEN,
  // ROUTES.ROOT_SCREEN,
  ROUTES.FORGOT_PASSWORD_SCREEN,
  ROUTES.CONFIRMATION_SCREEN,
  ROUTES.RESET_PASSWORD_SCREEN,
  ROUTES.RESET_CONFIRMATION_SCREEN,
];
