export const AUDITOR = 'AUDITOR';
export const STATUS_FILTER = {
  onHold: 'ON HOLD',
  awaitingAudit: 'AWAITING AUDIT',
  inProgress: 'IN PROGRESS',
  inReview: 'IN REVIEW',
  awaitingReview: 'AWAITING REVIEW',
  archived: 'ARCHIVED',
  awaitingAssignment: 'AWAITING ASSIGNMENT',
  qaRebuttal: 'QA REBUTTAL',
  clientRebuttal: 'CLIENT REBUTTAL',
};
export const SELF = 'self';
export const ALL = ['AWAITING AUDIT', 'IN PROGRESS'];
export const NEW_UPLOAD = ['AWAITING AUDIT'];
export const ARCHIVED = 'ARCHIVED';
export const REBBUTTAL = ['QA REBUTTAL'];
export const URGENT = ['QA REBUTTAL', 'IN PROGRESS', 'AWAITING AUDIT'];

export const getAuditStatus = props => {
  switch (props) {
    case 'ON HOLD':
      return 'On Hold';
    case 'AWAITING AUDIT':
      return 'Awaiting Audit';
    case 'IN PROGRESS':
      return 'In Progress';
    case 'IN REVIEW':
      return 'In Progress';
    case 'AWAITING REVIEW':
      return 'Awaiting Review';
    case 'ARCHIVED':
      return 'Archived';
    case 'AWAITING ASSIGNMENT':
      return 'Awaiting Assignment';
    case 'QA REBUTTAL':
      return 'QA Rebuttal';
    case 'CLIENT REBUTTAL':
      return 'Client Rebuttal';
    default:
      return 'n/a';
  }
};
export const SIZE = 10;
export const AWAITING_AUDIT = 'AWAITING AUDIT';
export const MY_AUDITS_ORDERING = [
  'chart_id',
  'upload_date',
  'client',
  'specialty__name',
  'total_pages',
  'status',
  'updated_date',
  'auditor',
  'qa',
  'audited_date',
  '-chart_id',
  '-upload_date',
  '-client',
  '-specialty__name',
  '-total_pages',
  '-status',
  '-updated_date',
  '-auditor',
  '-qa',
  '-audited_date',
];
export const REQUIRED_FIELDS = [
  'encounter_no',
  'rendering',
  'enc_dt',

  'srvcs_no',
  // 'billed_cpt',
  'provider_rvu',
  'provider_dollar_value',

  'response',
  'agree',
  'disagree',
  
  'audited_cpt',

  'audited_rvu',
  'audited_dollar_value',
];
export const ROLES_VALUES = {
  QA: 'QA',
  AUDITOR: 'Auditor',
  MANAGER: 'Manager',
  CLIENT: 'Client',
};

//  new
export const defaultColumns = [
  'provider_education',
  'number_complexity',
  'amount_complexity',
  'risk_complications',
];

export const addionalAttributesKeys = [
  'srvcs_no_',

  'icd_agree',
  'icd_disagree',

  'billed_cpt',
  'billed_cpt_',

  'billed_modifier',
  'billed_modifier_',

  'audited_icd_',

  'audited_cpt',
  'audited_cpt_',

  'audited_modifier',
  'audited_modifier_',

  ...defaultColumns,
];

export const auditedRuvColumns = [
  'audited_rvu',
  'audited_dollar_value',
  'notes',
];

export const defaultDataProps = ['file_name', 'chart_id', 'id', 'row_id'];

export const firstSetColumnKeyOrder = ['missing_info', 'encounter_no', 'rendering', 'enc_dt'];

export const rvuColumnsOrder = [
  'provider_rvu',
  'provider_dollar_value',
  'response',
  'agree',
  'disagree',
  'icd_agree',
  'icd_disagree',
];


export const DYNAMIC_AUDIT_SHEET_KEY = [
  'missing_info',
  'encounter_no',
  'rendering',
  'enc_dt',

  'srvcs_no',
  'billed_modifier',

  'provider_rvu',
  'provider_dollar_value',
  'response',
  'agree',
  'disagree',

  // 'audited_icd',

  'audited_code',

  'audited_cpt',
  'audited_modifier',

  'audited_rvu',
  'audited_dollar_value',
  'notes',

  'row_id',
  'file_name',
  'chart_id',
  'id'
];