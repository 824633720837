import { useContextMenu } from "../hooks";
import { Motion, spring } from "react-motion";
import { Box } from "@chakra-ui/react";
const ContextMenu = ({ menu }) => {
    const { showMenu } = useContextMenu();
    return (
        <Motion
            defaultStyle={{ opacity: 0 }}
            style={{ opacity: !showMenu ? spring(0) : spring(1) }}
        >
            {(interpolatedStyle) => (
                <>
                    {showMenu ? (
                        <Box
                            shadow={'md'} bg="white" p={4}
                            className="menu-container"
                            style={{
                                borderRadius: '12px',
                                position: 'absolute',
                                bottom: 50,
                                left: 40,
                                opacity: interpolatedStyle.opacity,
                            }}
                        >
                            {menu()}
                        </Box>
                    ) : (
                        <></>
                    )}
                </>
            )}
        </Motion>
    );
};
export default ContextMenu