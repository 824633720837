import { Box, Text } from "@chakra-ui/react";
import React from "react";
import AppColors from "../../../../constants/AppColors";
import { RESOLVED_STATUS } from "../../constants";

const CustomMenu = React.forwardRef((props, ref) => {
    const isResolved = () => {
        if (!props.comment.parent) {
            const replyLength = props.comment.reply_comments.length
            if (replyLength >= 1 && RESOLVED_STATUS.includes(props.comment.reply_comments[replyLength - 1].action)) {
                return true
            } else {
                return false
            }
        }
        return false
    }
    return <Box
        p={3}
        position={'absolute'}
        top={7} right={7}
        borderRadius='lg'
        shadow={'md'}
        color={AppColors.secondary}
        width={100}
        bg='white'
        zIndex={1000}
        ref={ref}
    >
        {!props.comment.audit_sheet_rows.length ?
            <Box>
                <Text fontSize={'xs'} cursor={'pointer'} onClick={() => props.onEditClick()}>
                    Edit
                </Text>
                <Text mt={2} fontSize={'xs'} cursor={'pointer'} onClick={() => props.onDeleteClick()}
                >
                    Delete
                </Text>
            </Box>
            : isResolved() ? <Text fontSize={'xs'} cursor={'pointer'} onClick={props.onReopenClick}>
                Re-open
            </Text> : <Box>
                {!props.comment.parent ? <Text mb={2} fontSize={'xs'} cursor={'pointer'}
                    onClick={props.onReplyClick}
                >
                    Reply
                </Text> : null}
                <Text fontSize={'xs'} cursor={'pointer'} onClick={() => props.onEditClick()}>
                    Edit
                </Text>
                <Text mt={2} fontSize={'xs'} cursor={'pointer'} onClick={() => props.onDeleteClick()}
                >
                    Delete
                </Text>
                {!props.comment.parent ? <Text fontSize={'xs'} mt={2} cursor={'pointer'} onClick={props.onResolveClick}
                >
                    Resolve
                </Text> : null}
            </Box>}
    </Box>
})

export default CustomMenu