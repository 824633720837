import React from 'react';
import { Outlet } from 'react-router-dom';

const BlankLayout = props => {

  return (
    <React.Fragment>
      {props.children}
      <Outlet />
    </React.Fragment>
  );
};

export default BlankLayout;
