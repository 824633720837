import CodeQuickShortIconData from '../assets/images/revintegrity-logo-no-bg.png';
import BellIconData from '../assets/icons/bell-icon.svg';
import MailIconData from '../assets/icons/mail-icon.svg';
import LockIconData from '../assets/icons/lock-icon.svg';
import EyeHiddenIconData from '../assets/icons/eye-hidden-icon.svg';
import EyeIconData from '../assets/icons/eye-icon.svg';
import UserIconData from '../assets/icons/user-icon.svg';
import HelpIconData from '../assets/icons/help-icon.svg';
import LogoutIconData from '../assets/icons/logout-icon.svg';
import AuditorIconData from '../assets/icons/auditor-logo.svg';
import WhiteBellIconData from '../assets/icons/white-bell-icon.svg';
import PersonIconData from '../assets/icons/person-icon.svg';
import EditIconData from '../assets/icons/edit-icon.svg';
import ViewIconData from '../assets/icons/view-icon.svg';
import MarkIconData from '../assets/icons/mark-icon.svg';
import UrgentIconData from '../assets/icons/urgent-icon.svg';
import OpenIconData from '../assets/icons/open-icon.svg';
import CloseIconData from '../assets/icons/close-icon.svg';
import LeftIconData from '../assets/icons/left.svg';
import RightIconData from '../assets/icons/right.svg';
import EyeAvatarIconData from '../assets/icons/eye-avatar-icon.svg';
import SortIconData from '../assets/icons/sort-icon.svg';
import RebuttalIconData from '../assets/icons/rebuttal-icon.svg';
import UpIconData from '../assets/icons/up-icon.svg';
import DownIconData from '../assets/icons/down-icon.svg';
import Group from '../assets/images/revintegrity-logo-with-bg.jpg';
import SuccessToastIconData from '../assets/icons/success-toast-icon.svg';
import NoteIconData from '../assets/icons/note-icon.svg';
import SheetEyeIconData from '../assets/icons/sheet-eye-icon.svg';
import CommentIconData from '../assets/icons/comment-icon.svg';
import BackIconData from '../assets/icons/back-icon.svg';
import LeftTriangleIconData from '../assets/icons/left-triangle-icon.svg';
import RightTriangleIconData from '../assets/icons/right-triangle-icon.svg';
import PlusIconData from '../assets/icons/plus-icon.svg';
import MinusIconData from '../assets/icons/minus-icon.svg';
import XIconData from '../assets/icons/x-icon-gray.svg';
import CheckedSquareIconData from "../assets/icons/checked-square.svg"
import UpDownIconData from "../assets/icons/up-down-arrows.svg"
import SortUpIconData from "../assets/icons/up-arrow.svg"
import SortDownIconData from "../assets/icons/down-arrow.svg"
import DotMenuIconData from '../assets/icons/dot-menu-icon.svg';
import YellowTickIconData from '../assets/icons/yellow-tick-icon.svg';
import XIconGrayData from "../assets/icons/x-icon-gray.svg"
import LeftEnableIconData from "../assets/icons/left-enable-icon.svg"
import RightEnableIconData from "../assets/icons/right-enable-icon.svg"

const CodeQuickIcon = props => (
  <img alt="code-quick-short-icon" src={CodeQuickShortIconData} {...props} />
);
const BellIcon = props => (
  <img
    alt="bell-icon"
    src={BellIconData}
    style={{ width: 25, height: 25 }}
    {...props}
  />
);
const MailIcon = props => (
  <img
    alt="mail-icon"
    src={MailIconData}
    style={{ width: 18, height: 18 }}
    {...props}
  />
);
const LockIcon = props => (
  <img
    alt="lock-icon"
    src={LockIconData}
    style={{ width: 18, height: 18 }}
    {...props}
  />
);
const EyeHiddenIcon = props => (
  <img
    alt="eye-hidden-icon"
    src={EyeHiddenIconData}
    style={{ width: 18, height: 18 }}
    {...props}
  />
);
const EyeIcon = props => (
  <img
    alt="eye-icon"
    src={EyeIconData}
    style={{ width: 18, height: 18 }}
    {...props}
  />
);
const UserIcon = props => (
  <img
    alt="user-icon"
    src={UserIconData}
    style={{ width: 10, height: 10 }}
    {...props}
  />
);
const HelpIcon = props => (
  <img
    alt="help-icon"
    src={HelpIconData}
    style={{ width: 10, height: 10 }}
    {...props}
  />
);
const LogoutIcon = props => (
  <img
    alt="logout-icon"
    src={LogoutIconData}
    style={{ width: 10, height: 10 }}
    {...props}
  />
);
const AuditorIcon = props => (
  <img
    alt="auditor-icon"
    src={AuditorIconData}
    style={{ width: 200, height: 40 }}
    {...props}
  />
);
const WhiteBellIcon = props => (
  <img
    alt="white-bell-quick-short-icon"
    src={WhiteBellIconData}
    style={{ width: 40, height: 40 }}
    {...props}
  />
);
const PersonIcon = props => (
  <img
    alt="person-icon"
    src={PersonIconData}
    style={{ width: 18, height: 18 }}
    {...props}
  />
);
const EditIcon = props => (
  <img
    alt="edit-icon"
    src={EditIconData}
    style={{ width: 18, height: 18 }}
    {...props}
  />
);
const ViewIcon = props => (
  <img
    alt="view-icon"
    src={ViewIconData}
    style={{ width: 18, height: 18 }}
    {...props}
  />
);
const MarkIcon = props => (
  <img
    alt="eye-icon"
    src={MarkIconData}
    style={{ width: 84, height: 84 }}
    {...props}
  />
);
const UrgentIcon = props => (
  <img
    alt="urgent-icon"
    src={UrgentIconData}
    style={{ width: 10, height: 10 }}
    {...props}
  />
);
const OpenIcon = props => (
  <img
    alt="open-icon"
    src={OpenIconData}
    style={{ width: 15, height: 15 }}
    {...props}
  />
);
const CloseIcon = props => (
  <img
    alt="client-selection-icon"
    src={CloseIconData}
    style={{ width: 30, height: 30 }}
    {...props}
  />
);
const RightIcon = props => (
  <img
    alt="right-icon"
    src={RightIconData}
    style={{ width: 40, height: 40 }}
    {...props}
  />
);
const LeftIcon = props => (
  <img
    alt="left-icon"
    src={LeftIconData}
    style={{ width: 40, height: 40 }}
    {...props}
  />
);
const EyeAvatarIcon = props => (
  <img
    alt="eye-avatar-icon"
    src={EyeAvatarIconData}
    style={{ width: 40, height: 40 }}
    {...props}
  />
);
const SortIcon = props => (
  <img
    alt="sort-icon"
    src={SortIconData}
    style={{ width: 14, height: 14 }}
    {...props}
  />
);
const RebuttalIcon = props => (
  <img
    alt="rebuttal-icon"
    src={RebuttalIconData}
    style={{ width: 40, height: 40 }}
    {...props}
  />
);
const UpIcon = props => (
  <img
    alt="Up-icon"
    src={UpIconData}
    style={{ width: 40, height: 40 }}
    {...props}
  />
);
const DownIcon = props => (
  <img
    alt="Down-icon"
    src={DownIconData}
    style={{ width: 40, height: 40 }}
    {...props}
  />
);

const AuditorPortalLogo = props => (
  <img alt="Auditor-Portal-Logo" src={Group} {...props} />
);

const SuccessToastIcon = props => (
  <img
    alt="success-toast-icon"
    src={SuccessToastIconData}
    style={{ width: 60, height: 60 }}
    {...props}
  />
);
const NoteIcon = props => (
  <img
    alt="Note-icon"
    src={NoteIconData}
    style={{ width: 8, height: 8 }}
    {...props}
  />
);
const SheetEyeIcon = props => (
  <img
    alt="sheet-eye-icon"
    src={SheetEyeIconData}
    style={{ width: 8, height: 8 }}
    {...props}
  />
);
const CommentIcon = props => (
  <img
    alt="comment-icon"
    src={CommentIconData}
    style={{ width: 8, height: 8 }}
    {...props}
  />
);
const BackIcon = props => (
  <img
    alt="sheet-eye-icon"
    src={BackIconData}
    style={{ width: 8, height: 8 }}
    {...props}
  />
);
const LeftTriangleIcon = props => (
  <img
    alt="LeftTriangle-icon"
    src={LeftTriangleIconData}
    style={{ width: 8, height: 8 }}
    {...props}
  />
);
const RightTriangleIcon = props => (
  <img
    alt="right-triangle-icon"
    src={RightTriangleIconData}
    style={{ width: 8, height: 8 }}
    {...props}
  />
);
const PlusIcon = props => (
  <img
    alt="plus-icon"
    src={PlusIconData}
    style={{ width: 8, height: 8 }}
    {...props}
  />
);
const MinusIcon = props => (
  <img
    alt="minus-icon"
    src={MinusIconData}
    style={{ width: 8, height: 8 }}
    {...props}
  />
);
const XIcon = props => (
  <img
    alt="X-icon"
    src={XIconData}
    style={{ width: 8, height: 8 }}
    {...props}
  />
);
const CheckedSquareIcon = props => (<img alt='checked-square-icon' src={CheckedSquareIconData} style={{ width: 20, height: 20 }} {...props} />);
const UpDownIcon = props => (
  <img
    alt="up-down-icon"
    src={UpDownIconData}
    style={{ width: 18, height: 18 }}
    {...props}
  />
);
const SortUpIcon = props => (
  <img
    alt="sort-up-icon"
    src={SortUpIconData}
    style={{ width: 18, height: 18 }}
    {...props}
  />
);
const SortDownIcon = props => (
  <img
    alt="sort-down-icon"
    src={SortDownIconData}
    style={{ width: 18, height: 18 }}
    {...props}
  />
);
const YellowTickIcon = props => (<img alt='yellow-tick-icon' src={YellowTickIconData} style={{ width: 20, height: 20 }} {...props} />);
const DotMenuIcon = props => (<img alt='dot-menu-icon' src={DotMenuIconData} style={{ width: 20, height: 20 }} {...props} />);
const XIconGray = props => (<img alt='X-icon-gray' src={XIconGrayData} style={{ width: 8, height: 8 }} {...props} />);

const LeftEnableIcon = props => (
  <img
    alt="code-quick-short-icon"
    src={LeftEnableIconData}
    style={{ width: 40, height: 40 }}
    {...props}
  />
);

const RightEnableIcon = props => (
  <img
    alt="code-quick-short-icon"
    src={RightEnableIconData}
    style={{ width: 40, height: 40 }}
    {...props}
  />
);

export {
  CodeQuickIcon,
  BellIcon,
  MailIcon,
  LockIcon,
  EyeHiddenIcon,
  EyeIcon,
  UserIcon,
  HelpIcon,
  LogoutIcon,
  AuditorIcon,
  WhiteBellIcon,
  MarkIcon,
  ViewIcon,
  EditIcon,
  PersonIcon,
  UrgentIcon,
  CloseIcon,
  OpenIcon,
  RightIcon,
  LeftIcon,
  EyeAvatarIcon,
  SortIcon,
  RebuttalIcon,
  UpIcon,
  DownIcon,
  AuditorPortalLogo,
  SuccessToastIcon,
  NoteIcon,
  SheetEyeIcon,
  CommentIcon,
  BackIcon,
  LeftTriangleIcon,
  RightTriangleIcon,
  PlusIcon,
  MinusIcon,
  XIcon,
  CheckedSquareIcon,
  UpDownIcon,
  SortUpIcon,
  SortDownIcon,
  YellowTickIcon,
  DotMenuIcon,
  XIconGray,
  LeftEnableIcon,
  RightEnableIcon,
};
