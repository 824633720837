export function toTitleCase(str) {
  const transformedStr = str
    .split(' ')
    .map(w => w[0]?.toUpperCase() + w.substring(1)?.toLowerCase())
    .join(' ');

  return transformedStr;
}

export function replaceRoute(route, params, delimiter = ':') {
  const keys = Object.keys(params);
  for (let key of keys) {
    route = route.replace(delimiter + key, params[key]);
  }
  return route;
}
