import { API } from '../config/AppConfig';
import {
  GET_NOTIFICATIONS,
  CHANGE_NOTIFICATION_STATUS,
} from '../constants/Endpoints';

class NotificationRepository {
  async getNotifications(currentPage) {
    const response = await API.get(GET_NOTIFICATIONS, {
      queryParams: { page: currentPage },
    });
    return response;
  }

  async markRead(notificationId, body) {
    const response = await API.patch(CHANGE_NOTIFICATION_STATUS, {
      pathParams: { notificationId },
      body: {
        read: true,
      },
    });
    return response;
  }
}

const notificationRepository = new NotificationRepository();
export default notificationRepository;
