import ROUTES from "../constants/Routes";
import ConfirmationContainer from "../pages/auth/forgot_password/ConfirmationContainer";
import ForgotPasswordContainer from "../pages/auth/forgot_password/ForgotPasswordContainer";
import LoginContainer from "../pages/auth/login/LoginContainer";
import ResetConfirmationContainer from "../pages/auth/reset_password/ResetConfirmationContainer";
import ResetPasswordContainer from "../pages/auth/reset_password/ResetPasswordContainer";

const AuthRouter = [{
  route: ROUTES.LOGIN_SCREEN,
  element: <LoginContainer />,
},
{
  route: ROUTES.FORGOT_PASSWORD_SCREEN,
  element: <ForgotPasswordContainer />,
},
{
  route: ROUTES.CONFIRMATION_SCREEN,
  element: <ConfirmationContainer />
},
{
  route: ROUTES.RESET_PASSWORD_SCREEN,
  element: <ResetPasswordContainer />
},
{
  route: ROUTES.RESET_CONFIRMATION_SCREEN,
  element: <ResetConfirmationContainer />
}
];

export default AuthRouter;
