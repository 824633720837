import { Button, useMultiStyleConfig, useTab,Text } from "@chakra-ui/react"
import React from "react"
import './tabStyle.css'
import AppColors from '../constants/AppColors';

const CustomTab = React.forwardRef((props, ref) => {
    // 1. Reuse the `useTab` hook
    const tabProps = useTab({ ...props, ref })
    const isSelected = !!tabProps['aria-selected']
    // 2. Hook into the Tabs `size`, `variant`, props
    const styles = useMultiStyleConfig('Tabs', tabProps)
    return (
        <Button
          __css={styles.tab}
          {...tabProps}
          _selected={{
            fontWeight: 'bold',
            color: AppColors.secondary,
          }}
          className={isSelected ? 'primary-underline' : ''}
          {...props}
        >
          <Text
            color={isSelected ? AppColors.secondary : 'black'}
            fontWeight={isSelected ?'bold':'none'}
            fontSize={'18px'}
          >
            {tabProps.children}
          </Text>
        </Button>
      );
    });
    export { CustomTab };