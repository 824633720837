/* eslint-disable array-callback-return */
const getTaggedUser = (comment) => {
    let userTagged = []
    const strArray = comment.match(/@\[(.\d*?)]/g);
    if (strArray && strArray.length) {
        strArray.map(str => {
            const matchedRegex = str.match(/@\[(.\d*?)]/);
            // Handle user mention
            if (matchedRegex && matchedRegex.length > 0 && !userTagged.includes(matchedRegex[1])) {
                const userId = matchedRegex[1];
                userTagged.push(Number(userId));
            }
        })
    }

    return userTagged
}
export { getTaggedUser }