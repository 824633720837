import { AUDIT_SHEET_KEY } from "./constants"

export const getRowColArray = (selection, sheet) => {
    const { start, end } = selection
    const row = []
    const column = []
    //get row
    for (let i = start.row; i <= end.row; i++) {
        row.push(sheet.data[i].id)
    }

    for (let i = start.col; i <= end.col; i++) {
        column.push(AUDIT_SHEET_KEY[i])
    }
    return { row, column }
}
export const getRowColIndex = (selection, sheet) => {
    const { start, end } = selection
    const rows = []
    const columns = []
    //get row
    for (let i = start.row; i <= end.row; i++) {
        rows.push(i)
    }
    for (let i = start.col; i <= end.col; i++) {
        columns.push(AUDIT_SHEET_KEY[i])
    }
    return { rows, columns, }
}