import {
  Heading,
  Image,
  Text,
  VStack,
  Divider,
  Box,
  Stack,
} from '@chakra-ui/react';
import AppColors from '../constants/AppColors';
import { CQLoginImage, LoginCardWatermark } from '../constants/ImageData';
import { FONT_FAMILY } from '../constants/Theme';

function renderCoverImage() {
  return (
    <VStack
      bgImage={LoginCardWatermark}
      display={{ base: 'none', md: 'flex' }}
      backgroundRepeat={'no-repeat'}
      backgroundSize={'cover'}
      py={32}
      rounded="md"
      px={8}
      flex={1}
      textAlign={'center'}
      spacing={8}
      justifyContent={'center'}
      bgColor={AppColors.secondary}
      zIndex={1}
      borderRadius={'3xl'}
    >
      <Image boxSize={'8rem'} alt={'cq-image-logo'} src={CQLoginImage} />
        <Text
          fontFamily={FONT_FAMILY.baiJamurjee}
          fontSize={'lg'}
          letterSpacing={'1.5px'}
          fontWeight={'bold'}
          textTransform={'uppercase'}
          color={'#fff'}
        >
          RevIntegrity
        </Text>
      <Box
        boxSizing={'border-box'}
        width={'92px'}
        border={'2px solid #FFFFFF '}
        opacity={'0.3'}
        borderRadius={'full'}
      />
      <Stack mt={5}>
        <Heading
          color={'white'}
          // fontWeight={'bold'}
          letterSpacing={'1px'}
          fontSize={'30px'}
          fontFamily={FONT_FAMILY.baiJamurjee}
        >
          Auditor's Portal
        </Heading>
        <Stack px={10}>
          <Text
            mt={3}
            opacity={0.5}
            fontSize={'18px'}
            fontFamily={FONT_FAMILY.ptSans}
            color={'white'}
          >
            This portal is designed to provide easy access
            <Text>
              to insurance options for auditors. Charts are audited and sent for
              insurance claims.
            </Text>
          </Text>
        </Stack>
      </Stack>
    </VStack>
  );
}
export { renderCoverImage };
